import React from "react";
import { css } from '@emotion/core';
import Layout from '../components/layout';

export default () => {
  return (
    <Layout>
      <section css={css`
        margin: 5rem auto;
        width: 95%;
      `}>
        <h1>Page not found</h1>
        <p>Oops! The page you are looking for has been removed or relocated.</p>
      </section>
    </Layout>
  );
};
